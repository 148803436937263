import { MasterComponent } from './master/master.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { DetailComponent } from './detail/detail.component';
import { ReadDetailsComponent } from './read-details/read-details.component';
import { VideoComponent } from './video/video.component';

const routes: Routes = [
  {
    path: ':lang', component: MasterComponent,
    pathMatch: 'full',
  },
  {
    path: 'contents/:menuId/:lang', component: MasterComponent,
    pathMatch: 'full'
  },
  { path: 'show-detail/:term', component: VideoComponent },//DetailComponent },
  { path: 'details/:collection/:newsId/:readDetails/:lang', component: MasterComponent,// , ReadDetailsComponent
    pathMatch: 'full'
  },
  { path: 'details/:collection/:newsId/:readDetails/:version/:page_id/:page_type/:par/:lang', component: MasterComponent,// , ReadDetailsComponent
    pathMatch: 'full'
  },  
  {
    path: '**',
    redirectTo: '/km',
    pathMatch: 'full',
  },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
