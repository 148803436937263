import { TranslateService } from "./../translate.service";
import { ActivatedRoute, Router,Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Component, OnInit, AfterViewInit, ViewChild,HostListener,ChangeDetectorRef} from "@angular/core";

import { ToolsComponent } from "./../tools/tools.component";
import { MatTabGroup } from "@angular/material";
import { ɵHttpInterceptingHandler } from '@angular/common/http';
import {SharedDataService} from '../services/sharedDataService';

import { LOCALE_ID } from '@angular/core';

@Component({
  selector: "app-master",
  templateUrl: "./master.component.html",
  styleUrls: ["./master.component.css"]
})
export class MasterComponent implements OnInit {
  @ViewChild(ToolsComponent, { static: false })
  private toolsComponent: ToolsComponent;
  currentLanguage = "km";
  readDetail="";
  language="";
  currentUrl="";
  urlPath =[];
  i:number=0;
  arrLength:number=0;
  sticky: boolean = false;
  pageNotFound=false;
  menuPosition: any;
  @ViewChild("stickyMenu",{ static: false }) menuElement;
  mobile_sidenav=0; //set margin-top of menu on SM, XS screen size
  
  //
  colorList = [ "goldenrod", "white", "white", "white", "white", "white", "white"];
  // colorList = [];


  constructor(
    private translate: TranslateService,private router: Router, 
    private route: ActivatedRoute,private cdr: ChangeDetectorRef,
    private sharedDataService:SharedDataService,
   
    ) 
    {
    this.route.params.subscribe( params => this.doTest(params));
    }
  
  doTest(params){

    if(params['readDetails']=='YES')
    {
      this.readDetail="YES";
      this.language=params['lang'];

      if(this.language=="kh")
      {
        this.language="km";
      }
      if((params['collection']=='compendium' || params['collection']=='article')&&(this.language=="km" || this.language=="en" || this.language=="fr"))
      {
        this.onReadDetail();
      }
      else
      {
        this.pageNotFound=true;
        this.sharedDataService.changeMessage(this.pageNotFound);
      }
    }
    else if(params['menuId']){
      this.readDetail="";
      this.currentLanguage=params['lang'];
      if(this.currentLanguage=="kh")
      {
        this.currentLanguage="km";
      }
      if(Math.sign(Number(params['menuId']))==1 && Number(params['menuId'])<=(this.colorList.length-1)&&(this.currentLanguage=="km" || this.currentLanguage=="en" || this.currentLanguage=="fr"))
      {
        this.onHomeClicked(params['menuId']);
        this.setLang(this.currentLanguage);
      }
      else
      {
        this.pageNotFound=true;
        this.sharedDataService.changeMessage(this.pageNotFound);
      }
    }
    else{
      this.readDetail="";
      this.currentLanguage=params['lang'];
      if(this.currentLanguage!="km" && this.currentLanguage!="en" && this.currentLanguage!="fr")
      {
        if(this.currentLanguage=="kh")
        {
          this.currentLanguage='km';
          this.translate.use(this.currentLanguage);
          this.router.navigate(['']);
          return;
        }
        this.pageNotFound=true;
        this.sharedDataService.changeMessage(this.pageNotFound);
      }
      this.setLang(this.currentLanguage);
    }

  }
  setLang(lang: string) {
    this.translate.use(lang);
    this.currentLanguage = lang;
    // console.log("current lang : " + this.currentLanguage);

    this.currentUrl=this.router.url;
    this.urlPath=this.currentUrl.split('/');
    this.arrLength=this.urlPath.length;
    this.urlPath[this.arrLength-1]=this.currentLanguage;
    this.currentUrl="";
    for(this.i=0;this.i<this.arrLength;this.i++){
      this.currentUrl+=this.urlPath[this.i]+'/'
    }
    this.currentUrl=this.currentUrl.substring(1,(this.currentUrl.length-1));
    this.router.navigate([this.currentUrl]);

  }
  //scroll dock on top for Menu for desktop & mobile
  ngAfterViewInit(){
    this.menuPosition = this.menuElement._elementRef.nativeElement.offsetTop;
    this.mobile_sidenav=this.menuPosition+this.menuElement._elementRef.nativeElement.offsetHeight;
    this.cdr.detectChanges(); // To prevent error of Expression ___ has changed after it was checked
  }
  //on scroll event we add sticky class;
  @HostListener('window:scroll', ['$event'])
    handleScroll(){
        const windowScroll = window.pageYOffset;
        if(windowScroll >= this.menuPosition){
            this.sticky = true;
            let tempMenuPosition=this.menuElement._elementRef.nativeElement.offsetTop;
            this.mobile_sidenav=tempMenuPosition+this.menuElement._elementRef.nativeElement.offsetHeight;

        } else {
            this.sticky = false;
            this.mobile_sidenav=this.menuPosition+this.menuElement._elementRef.nativeElement.offsetHeight;
        }
    }
  //end scroll dock on top

  ngOnInit() 
  {}

  onMenuClicked(index) {
    this.router.navigate(['contents',index,this.currentLanguage]);
  }
  onHome(){
    this.router.navigate([this.currentLanguage]);
  }
  onHomeClicked(selectedIndex: number) {
    selectedIndex=Number(selectedIndex);
    for (let i = 0; i < this.colorList.length; i++) {

      if (i === selectedIndex) {
        this.colorList[i] = "goldenrod";
      } else {
        this.colorList[i] = "white";
      }
    }
  }
  onReadDetail(){
    if(this.readDetail=="YES") {
      this.currentLanguage=this.language;
      this.setLang(this.currentLanguage);
      for (let i = 0; i < this.colorList.length; i++) {
        this.colorList[i] = "white";
      }
    }
  }

    

}
