

export class ApiModel
{
    public_key:	string;
    private_key: string;
    encryptedData:string;
    id:	string;
    modified_date: string;
    limit:number;
    cateType: string;
    category: string;
    keyword: string;
    sortBy: string;
    isNext: number;
    oldReq: string;
    //other tab
    types: any;
}