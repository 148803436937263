
import {JSEncryptRSAKey} from "src/assets/jsencrypt/JSEncryptRSAKey";

export class JSGenerateRSAKey{
    key:JSEncryptRSAKey;

    public getKey(cb?:() => void) {
        // Only create new if it does not exist.
        if (!this.key) {
            // Get a new private key.
            this.key = new JSEncryptRSAKey();
            if (cb && {}.toString.call(cb) === "[object Function]") {
                this.key.generateAsync(1024, "010001", cb); //1024 = the key size in bit(default), 
                                                            //010001 = the hexadecimal representation of the public exponent(default)
                return;
            }
            // Generate the key.
            this.key.generate(1024, "010001");
        }
        return this.key;
    }
    public getOriginalPublicKey(){
        return this.getKey().getPublicKey();
    }
    public getPublicKey(){
        let key="-----BEGIN PUBLIC KEY-----";
        key+=this.getPublicKeyB64();
        key+="-----END PUBLIC KEY-----";
        return key;
    }
    public getPublicKeyB64() {
        // Return the private representation of this key.
        return this.getKey().getPublicBaseKeyB64();
    }

    public getOriginalPrivateKey(){
        return this.getKey().getPrivateKey();
    }
    public getPrivateKey() {
        let key="-----BEGIN RSA PRIVATE KEY-----";
        key+=this.getPrivateKeyB64();
        key+="-----END RSA PRIVATE KEY-----";
        return key;
    }
    public getPrivateKeyB64() {
        // Return the private representation of this key.
        return this.getKey().getPrivateBaseKeyB64();
    }

}