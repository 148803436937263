import {
  Component,
  OnInit,
  Inject,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  EventEmitter,
  Output,
  Input
} from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from "./../translate.service";

import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from "@angular/material/dialog";
import {swaggerApiService} from '../services/swaggerApi.service';
import { ApiModel } from '../classes/api_model';
import {JSGenerateRSAKey} from '../services/JSGenerateRSAKey';
import {MatSnackBar} from '@angular/material/snack-bar';
export interface DetailData {
  title: string;
  english: string;
  french: string;
  thumb: string;
  khmer: string;
  imgs: [];
  date: string;
}
import { ActivatedRoute, Router } from '@angular/router';
import { element } from 'protractor';
import { MatTabGroup } from "@angular/material";
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
  encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {
  @ViewChild("about", { static: false }) aboutTab: ElementRef;
  //
  @Output() newsEmitter = new EventEmitter();
  // toolList = [];
  isLoading = false;
  carouselList = [];
  newsList = [];
  length = 0;
  pageSize = 0;
  selectedTab = -1;
  key:JSGenerateRSAKey;
  @Input() currentLanguage: string;

  //get News from FB
  newsListFB=[];

  constructor(private db: AngularFirestore, public dialog: MatDialog,private route: ActivatedRoute,
    private router: Router,config: NgbCarouselConfig,private translate: TranslateService,private _swaggerApiService:swaggerApiService,private _snackBar: MatSnackBar) {
      // customize default values of carousels used by this component tree
    config.interval = 5000;
    config.keyboard = false;
    config.pauseOnHover = false;
    //end

    // this.db
    //   .collection("carousel")
    //   .get()
    //   .subscribe(queyrSnap => {
    //     queyrSnap.forEach(doc => {
    //       this.carouselList.push(doc.data());
    //     });
    //   });
    // this.db
    //   .collection("article", ref => ref.orderBy("create", "desc").limit(5))
    //   .get()
    //   .subscribe(queyrSnap => {
    //     queyrSnap.forEach(doc => {
    //       let docId = doc.id;
    //       let theDoc = doc.data();

    //       //
    //       //firestore.Timestamp.fromDate(jsDate
    //       let theDate = theDoc["create"];
    //       console.log("you will done it : " + theDate.seconds);
    //       //
    //       let str: String = theDoc.images;
    //       let imgs = str.split(",");
    //       theDoc.imgs = imgs;
    //       theDoc.newsId = docId;
    //       this.newsList.push(theDoc);
    //     });
    //     this.length = this.newsList.length;
    //     this.pageSize = 4;
    //   });
  }

  onNewsClicked() {
    console.log("click from home !!!");
    this.newsEmitter.emit();
    console.log("after emitter !!!");
  }

  onMenuClicked(index){
    this.router.navigate(['contents',index,this.currentLanguage]);
  }

  ngOnInit() 
  {
    this.key=new JSGenerateRSAKey();

    let objSliding=new ApiModel();
    objSliding.public_key=this.key.getPublicKey();
    objSliding.modified_date=new Date().toISOString();
    objSliding.limit=50;
    this._swaggerApiService.postCarousel(objSliding)
      .subscribe
      (
        data=>
        {
          let objDecrypt=new ApiModel();
          objDecrypt.encryptedData=data;
          objDecrypt.private_key=this.key.getPrivateKey();
          this._swaggerApiService.postDecrypted(objDecrypt)
            .subscribe
            (
              data=>
              {
                if(data.statusCode==200 && data.item.length>0)
                {
                  // console.info("caroucel = ",data);
                  data.item.forEach(element => 
                    {
                      this.carouselList.push(element);
                    }
                  );
                }
                else if(data.statusCode==200 && data.item.length<=0)
                {
                  //Toast message to users
                  this._snackBar.open("oops! no more records found.", "OK", {
                    duration: 2000
                  });
                }
                else
                {
                  //Toast message to users
                  this._snackBar.open(data.message, "OK", {
                    duration: 2000
                  });
                }
              },(error:HttpResponse<Response>) => {
                let errorMsg=error['message'];
                this._snackBar.open("Carousel Decrypted Res_Err: "+errorMsg, "OK", {
                  duration: 2000
                });
              }
            )
          //auto click the ABOUT_LC Tab
          this.clickNextTab();
          this.clickNextSubTab();
        },(error:HttpResponse<Response>) => {
          let errorMsg=error['message'];
          this._snackBar.open("Carousel Res_Err: "+errorMsg, "OK", {
            duration: 2000
          });
          //auto click the ABOUT_LC Tab
          this.clickNextTab();
          this.clickNextSubTab();
        }
      )
      this.recentlyEventsFB();
    //**to get recently event with swagger API**
    // let objNewsBody=new ApiModel();
    // objNewsBody.public_key=this.key.getPublicKey();
    // objNewsBody.modified_date=new Date().toISOString();
    // objNewsBody.limit=5;

    // this._swaggerApiService.postNewsGetLists(objNewsBody)
    //   .subscribe
    //   (
    //     data=>
    //     {
    //       let objDecrypt=new ApiModel();
    //       objDecrypt.encryptedData=data;
    //       objDecrypt.private_key=this.key.getPrivateKey();
    //       this._swaggerApiService.postDecrypted(objDecrypt)
    //         .subscribe
    //         (
    //           data=>
    //           {
    //             if(data.statusCode==200 && data.item.item.length>0){ //mean it is success
    //               data.item.item.forEach(element => {
    //                 this.newsList.push(element);
    //               });
    
    //             }
    //             else if(data.statusCode==200 && data.item.item.length<=0)
    //             {
    //               //Toast message to users
    //               this._snackBar.open("oops! no more records found.", "OK", {
    //                 duration: 2000
    //               });
    //             }
    //             else
    //             {
    //               //Toast message to users
    //               this._snackBar.open(data.message, "OK", {
    //                 duration: 2000
    //               });
    //             }
    //           },(error:HttpResponse<Response>) => {
    //             let errorMsg=error['message'];
    //             this._snackBar.open("NewEvents Decrypted Res_Err: "+errorMsg, "OK", {
    //               duration: 2000
    //             });
    //           }
    //         )
    //     },(error:HttpResponse<Response>) => {
    //       let errorMsg=error['message'];
    //       this._snackBar.open("NewEvents Res_Err: "+errorMsg, "OK", {
    //         duration: 2000
    //       });
    //     }
    //   )
    //**end recently event**
  }
  recentlyEventsFB()
  {
    this.isLoading = true;
    this._swaggerApiService.postPageToken().subscribe(data=>
      {
        let Datares=data;
        if(Datares.statusCode==200){
          let uri=Datares.item.token;
          //call get new Feed from FB graph API
          this.getNewFeedFBpage(uri);
        }
      },(error:HttpResponse<Response>) => 
      {
        //get error response from FB
        // let errorMsg=error['error'].error.message;
        this._snackBar.open(error['error'], "OK", {
          duration: 2000
        });
      }
    );
  }
  getNewFeedFBpage(uri){
    //old FB Token request
    // this._swaggerApiService.getNewsFB("https://graph.facebook.com/v6.0/1528139114067649/feed?fields=message,permalink_url,full_picture,attachments,created_time&limit=10&access_token=EAAP4Crx1tZAUBANewu0wDgrhJNoMFDzQYHS164b442GrsbmcGzjthZA9WZBLwdMvUFjAGYToPC7rBPoyNhPcFlRKpowqhKZAwB5ZAbJ2yxwlY24juUHqR1MDRkrEfgLZAcCZCNqb7H3ljCEuy5cBz64RH0QUpOsDeHJ01uzLRNrS9Knk3Emrey9")
    this._swaggerApiService.getNewsFB(uri)
    .subscribe
    (
      response =>
      {
        //old FB Token request
        // let current_url="https://graph.facebook.com/v6.0/1528139114067649/feed?fields=message,permalink_url,full_picture,attachments,created_time&limit=10&access_token=EAAP4Crx1tZAUBANewu0wDgrhJNoMFDzQYHS164b442GrsbmcGzjthZA9WZBLwdMvUFjAGYToPC7rBPoyNhPcFlRKpowqhKZAwB5ZAbJ2yxwlY24juUHqR1MDRkrEfgLZAcCZCNqb7H3ljCEuy5cBz64RH0QUpOsDeHJ01uzLRNrS9Knk3Emrey9";
        let current_url=uri;
        this.isLoading = true;
        // console.log('*****',this.isLoading );
        
        if(response.data.length>0)
        {
          response.data.forEach(element => 
          {
            if(element.message!=undefined)
            {
              //old FB Token request
              // element.message="កម្រងច្បាប់ខ្មែរពីបុរាណ ភាគ៣ និងភាគ៤\n----------\nជាសក្ខីកម្មប្រវត្តិសាស្ត្រជាតិបញ្ជាក់អំពីអត្ថិភាពនៃវប្បធម៌នីតិសាស្ត្រ ធម្មសាស្ត្រ និងយុត្តិធម៌របស់ខ្មែរ ដែលមានតាំងពីយូរលង់ណាស់មកហើយ គឺមុនពេលមកដល់នៃឥទ្ធិពលប្រព័ន្ធច្បាប់និងប្រព័ន្ធយុត្តិធម៌បារាំងទៅទៀត ដែលបន្តពីកម្រងច្បាប់ខ្មែរពីបុរាណ ភាគ១ និងភាគ២ ពោលគឺជាកម្រងច្បាប់ខ្មែរពីបុរាណឆ្នាំ១៨៩១។ \n|\nComplication of Khmer Ancient Law, Volume III and IV\n----------\nHistorical attestation of the Khmer legal, Dharma and judicial culture from the ancient time before the influence of the French culture and system. This Compilation is the sequel of Compilation of Khmer Ancient Law Part I and II, which are the compilation of laws in 1891. \n|\nCodes anciens du Cambodge (Volume 3 et Volume 4)\n----------\nLe Recueil des anciens codes khmers (2020) est un témoignage historique qui justifie l’existence de la culture juridique (de Nitisastra), de Dhammasastra et judiciaire (de la justice) khmère, depuis les temps anciens, avant que les systèmes juridique et judiciaire khmers ne subissent l’influence du système français. Ce Recueil a été produit à la suite de celui des anciens codes khmers de deux volumes (1891).";
              let newsEl=element;
              let sms=element.message.split("\n|\n");
              if(sms[0].indexOf("\n----------\n")!=-1)
              {
                newsEl.titleKh=sms[0].split("\n----------\n")[0];
                newsEl.khDes=sms[0].split("\n----------\n")[1];
                
                if(sms[1]!=undefined)
                {
                newsEl.titleEn=sms[1].split("\n----------\n")[0];
                newsEl.enDes=sms[1].split("\n----------\n")[1];
                }
                if(sms[2]!=undefined)
                {
                newsEl.titleFr=sms[2].split("\n----------\n")[0];
                newsEl.frDes=sms[2].split("\n----------\n")[1];
                }
              }
              else
              {
                newsEl.titleKh='';
                newsEl.titleEn='';
                newsEl.titleFr='';
  
                newsEl.khDes=element.message;
                newsEl.enDes=element.message;
                newsEl.frDes=element.message;
              }

              newsEl.url=current_url
              this.newsListFB.push(newsEl);
              this.isLoading = false;
              // console.log('####',this.isLoading );
            }
          });
        }
        else
        {
          this._snackBar.open("oops! no more records found.", "OK", {
            duration: 2000
          });
        }
        // console.info("response = ",response);
      },(error:HttpResponse<Response>) => 
        {
          //get error response from FB
          let errorMsg=error['error'].error.message;
          this._snackBar.open(errorMsg, "OK", {
            duration: 2000
          });
        }
    );
  }

  //auto click the ABOUT_LC Tab
  @ViewChild("demoTab", { static: false }) demoTab: MatTabGroup;

  public clickNextTab() {
    this.goToNextTabIndex(this.demoTab);
  }

    //auto click the ABOUT_LC Tab
    @ViewChild("demoSubTab", { static: false }) demoSubTab: MatTabGroup;

    public clickNextSubTab() {
      this.goToNextTabIndex(this.demoSubTab);
    }

  // ============
  // HELPER
  // ============

  private goToNextTabIndex(tabGroup: MatTabGroup) {
    if (!tabGroup || !(tabGroup instanceof MatTabGroup)) return;

    const tabCount = tabGroup._tabs.length;
    tabGroup.selectedIndex = (tabGroup.selectedIndex+1) % tabCount;
  }
  //end auto
  navigateDetailNewTab(news){
    // window.open('read-detail', '_blank');
    // this.router.navigate(['read-detail'], { queryParams: { data:JSON.stringify(news),newsList:JSON.stringify(this.newsList)} });
    // this.router.navigate(['read-detail',news.newsId]);
    let url=news.url.replace("https://graph.facebook.com/",'');
    url=url.split("/");
    // console.info("url = ",url);
    let parameter=url[2].split("?");
    // console.info("parameter = ",parameter);
    parameter[1]=parameter[1].replace(/=/g,'@@');
    let fb_url=url[0]+'/'+url[1]+'/'+parameter[0]+'/'+parameter[1];
    window.open('details/article/'+news.id+'/YES'+'/'+fb_url+'/'+this.currentLanguage,'_blank' );
  }
  toKhmerNumber(str){
    var khNumber = str.toString();

    khNumber = khNumber.replace(/0/g,'០')
    khNumber = khNumber.replace(/1/g,'១')
    khNumber = khNumber.replace(/2/g,'២')
    khNumber = khNumber.replace(/3/g,"៣")
    khNumber = khNumber.replace(/4/g,"៤")
    khNumber = khNumber.replace(/5/g,"៥")
    khNumber = khNumber.replace(/6/g,"៦")
    khNumber = khNumber.replace(/7/g,"៧")
    khNumber = khNumber.replace(/8/g,"៨")
    khNumber = khNumber.replace(/9/g,"៩")
    
    return khNumber
  }
  toKhmerMonth(str){
    var khNumber = str.toString();

    khNumber = khNumber.replace(/01/g,this.translate.data.MONTH_01)
    khNumber = khNumber.replace(/02/g,this.translate.data.MONTH_02)
    khNumber = khNumber.replace(/03/g,this.translate.data.MONTH_03)
    khNumber = khNumber.replace(/04/g,this.translate.data.MONTH_04)
    khNumber = khNumber.replace(/05/g,this.translate.data.MONTH_05)
    khNumber = khNumber.replace(/06/g,this.translate.data.MONTH_06)
    khNumber = khNumber.replace(/07/g,this.translate.data.MONTH_07)
    khNumber = khNumber.replace(/08/g,this.translate.data.MONTH_08)
    khNumber = khNumber.replace(/09/g,this.translate.data.MONTH_09)
    khNumber = khNumber.replace(/10/g,this.translate.data.MONTH_10)
    khNumber = khNumber.replace(/11/g,this.translate.data.MONTH_11)
    khNumber = khNumber.replace(/12/g,this.translate.data.MONTH_12)
    
    return khNumber
  }
  convertDate(date){
    let dateList,dateStr='';
    let dateLabel=['ថ្ងៃទី','ខែ','ឆ្នាំ'];
    dateList=date.split('-');
    dateList[1]=this.toKhmerMonth(dateList[1]);
    if(this.currentLanguage=='km'){
      for(let i=0;i<dateList.length;i++){
        dateList[i]=this.toKhmerNumber(dateList[i]);
        dateStr+=dateLabel[i]+dateList[i]+' ';
      }
    } else {
      for(let i=0;i<dateList.length;i++){
        dateStr+=dateList[i]+' ';
      }
    }
    return dateStr;
  }
  openDialog(news) {
    let str: String = news.images;
    let imgList = str.split(",");

    const dialogRef = this.dialog.open(NewsDetail, {
      data: {
        title: news.title,
        english: news.english,
        french: news.french,
        thumb: news.thumb,
        imgs: imgList,
        khmer: news.khmer
      }
    });
    // title: string;
    // english: string;
    // french: string;
    // img: string;
    // images: [];
    // date: string;
    dialogRef.afterClosed().subscribe(result => {});
  }

  // MatPaginator Output
}

@Component({
  selector: "news-detail",
  templateUrl: "news-detail.html"

})
export class NewsDetail {
  constructor(
    public dialogRef: MatDialogRef<NewsDetail>,
    @Inject(MAT_DIALOG_DATA) public data: DetailData
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
