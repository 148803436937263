import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from "./../translate.service";
import { ApiModel } from "../classes/api_model";
import { swaggerApiService } from "../services/swaggerApi.service";
import { JSGenerateRSAKey } from "../services/JSGenerateRSAKey";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-pdf-docs',
  templateUrl: './pdf-docs.component.html',
  styleUrls: ['./pdf-docs.component.css']
})
export class PdfDocsComponent implements OnInit {
  pdfList = [];
  cateDocList = [];
  isLoading = false;
  panelOpenState: boolean = false;
  @Input() currentLanguage: string;
  key: JSGenerateRSAKey;
  constructor(private db: AngularFirestore, public dialog: MatDialog, private router: Router, private translate: TranslateService, private _swaggerApiService: swaggerApiService, private _snackBar: MatSnackBar) { }

  public navigateToDetail(news) {
    localStorage.setItem('data', JSON.stringify(news));
    window.open('show-detail', '_blank');
  }
  toKhmerNumber(str) {
    var khNumber = str.toString();

    khNumber = khNumber.replace(/0/g, '០')
    khNumber = khNumber.replace(/1/g, '១')
    khNumber = khNumber.replace(/2/g, '២')
    khNumber = khNumber.replace(/3/g, "៣")
    khNumber = khNumber.replace(/4/g, "៤")
    khNumber = khNumber.replace(/5/g, "៥")
    khNumber = khNumber.replace(/6/g, "៦")
    khNumber = khNumber.replace(/7/g, "៧")
    khNumber = khNumber.replace(/8/g, "៨")
    khNumber = khNumber.replace(/9/g, "៩")

    return khNumber
  }
  convertYear(year) {
    let yearStr = year;
    if (this.currentLanguage == 'km') {
      yearStr = this.toKhmerNumber(year);
    }
    return yearStr;
  }
  ngOnInit() {
    this._swaggerApiService.getOtherTabCategory()
      .subscribe
      (
        data => {
          if (data.statusCode == 200 && data.item.length > 0) {
            data.item.forEach(element => {
              this.cateDocList.push(element);
            }
            )
          }
          else if (data.statusCode == 200 && data.item.length <= 0) {
            //Toast message to users
            this._snackBar.open("oops! no more records found.", "OK", {
              duration: 2000
            });
          }
          else {
            //Toast message to users
            this._snackBar.open(data.message, "OK", {
              duration: 2000
            });
          }
        }, (error: HttpResponse<Response>) => {
          let errorMsg = error['message'];
          this._snackBar.open("Other document category Res_Err: " + errorMsg, "OK", {
            duration: 2000
          });
        }
      )
  }
  eduPDF(event) {
    let cate_id = event.tab._viewContainerRef._view.component.cateDocList[event.index]._id; 
    this.loadFilePdfList(cate_id);
  }
  loadFilePdfList(category_id) {
    this.pdfList = [];
    let objCate = new ApiModel();
    this.isLoading = true;
    objCate.keyword = '';
    objCate.modified_date = new Date().toISOString();
    objCate.types = "[{\"id\":\"" + category_id + "\"}]";
    objCate.limit = 100;
    objCate.sortBy = 'desc';

    this._swaggerApiService.postOtherTab(objCate)
      .subscribe
      (
        data => {
          if (data.statusCode == 200 && data.item.total > 0) {
            data.item.item.forEach(element => {
              this.pdfList.push(element);
              this.isLoading = false;
            }
            );
          }
          else if (data.statusCode == 200 && data.item.total <= 0) {
            //Toast message to users
            this._snackBar.open("oops! no more records found.", "OK", {
              duration: 2000
            });
          }
          else {
            //Toast message to users
            this._snackBar.open(data.message, "OK", {
              duration: 2000
            });
          }
        }, (error: HttpResponse<Response>) => {
          let errorMsg = error['message'];
          this._snackBar.open("PDF List Res_Err: " + errorMsg, "OK", {
            duration: 2000
          });
        }
      )
  }
  findLang(cate) {
    if (this.currentLanguage == 'km' && (cate.nameKh != null && cate.nameKh != undefined && cate.nameKh != '')) {
      return cate.nameKh;
    } else if (this.currentLanguage == 'en' && (cate.nameEn != null && cate.nameEn != undefined && cate.nameEn != '')) {
      return cate.nameEn;

    } else if (this.currentLanguage == 'fr' && (cate.nameFr != null && cate.nameFr != undefined && cate.nameFr != '')) {
      return cate.nameFr;
    }
    return cate.nameKh;
  }
  // if (openReader == )
  openReader(url: string) {
    window.open(url, '_blank');
    // window.open(url);
    // window.open("./assets/1.pdf");
  }
  nav = (window.navigator as any);
  downloadFilePDF(url) {

    this._snackBar.open("Downloading...", "OK", {
      duration: 2000
    });
    this._swaggerApiService.getPDF(url)
      .subscribe(x => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        var newBlob = new Blob([x], { type: "application/pdf" });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && this.nav.msSaveOrOpenBlob) {
          this.nav.msSaveOrOpenBlob(newBlob);
          return;
        }

        // For other browsers: 
        // Create a link pointing to the ObjectURL containing the blob.
        // const data = window.URL.createObjectURL(newBlob); //previous version
        const data = URL.createObjectURL(newBlob);

        var link = document.createElement('a');
        link.href = data;
        //To get pdf file name from link
        var fileName = url.split("/");
        fileName = fileName[fileName.length - 1];
        link.download = fileName;//we want to set name of our download file
        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        this._snackBar.open("The download was successful.", "OK", {
          duration: 2000
        });
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);
      });
  }
}
