import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable,ReplaySubject } from 'rxjs';
export class SharedDataService {
    constructor(){}
    //Using any
    public pageNotFound: any = [];
    private notFoundSource = new  BehaviorSubject(this.pageNotFound);
    currentMessage = this.notFoundSource.asObservable();
    changeMessage(message: boolean) {
    this.notFoundSource.next(message)
    }
}